import { useParams } from 'react-router-dom';
export default function ProductFinder() {
    // start question / answer
    // display the pros / cons using an LLM answer
    // allow for going backwards in time
    const params = useParams();
    return (
        <>
        <iframe src={`https://qpid-test-0bc042d3ec0b.herokuapp.com?embed=true&variant=${params.variant}&subvariant=${params.subvariant}`} style={{height: '1000px', width: '100%'}}/>
        </>
    );
}
