import React, { useState, useEffect } from 'react';
import Datepicker from 'tailwind-datepicker-react';
import { Link } from 'react-router-dom';


interface ProgressProps {
    minAge: number;
    maxAge: number;
    name: string;
    description: string;
    have: boolean;
    minPrice: number;
    maxPrice: number;
    imgSrc: string;
    id: string;
    variant: string;
    disabled: boolean;
    subvariant: string;
}

interface ToggleProps {
    checked: any;
    setChecked: any;
}


function Toggle({ checked, setChecked}: ToggleProps) {
    return (
        <label className="inline-flex items-center cursor-pointer">
          <input type="checkbox" value="" className="sr-only peer" checked={checked()}
            onChange={(e) => setChecked(e.target.checked)}/>
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
    );
}

function ageDescription(months: number): string {
    if (months === 0) {
        return "Newborn";
    } else if (months < 12) {
        return `${months} months`;
    } else {
        const years = Math.floor(months / 12);
        return years === 1 ? "1 year" : `${years} years`;
    }
}

function ProgressBar({props, age} : {props: ProgressProps, age: number}) {
    const [checked, setChecked] = useState<boolean>(false);
    // onst [age, setAge] = useState<number>(6);

    // const age = 6;
    // If you are 6mo.
    // how wide should the first part be? It should be max(age - props.minAge, 0)
    // how much time is in the past that is irrelevant? as in we should gray out?
    // 
    //
    const shiftWidth    = 100 * (props.minAge / 60);
    const pastWidth     = 100 * (Math.max(Math.min(age, props.maxAge) - props.minAge, 0) / 60);
    const remWidth      = 100 * (Math.max(props.maxAge - age, 0) / 60);

    const saveToLocal = (id: string) => {
        return (have: boolean) => {
            localStorage.setItem(id, (have) ? 't': 'f');
            setChecked(have);
        }
    };


    const isChecked = (id: string) => {
        return (() => {
            const savedData = localStorage.getItem(id);
            if (savedData) {
                setChecked(savedData === 't');
                return checked;
            } else {
                setChecked(false);
                return checked;
            }
        });
    }

    const background = (checked) ? 'bg-green-500' : 'bg-gray-400';

    const hidden     = (props.subvariant === 'all types') ? '' : 'hidden-row';

    return (
        <>
            <tr className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${hidden}`}>
                <td className="px-6 py-4 min-w-[100px]"><Toggle checked={isChecked(props.id)} setChecked={saveToLocal(props.id)} /></td>
                <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    { props.disabled ? (
                        <span>{props.name}</span>
                    ) : (<Link to={`/product/${props.variant}/${props.subvariant}`}>{props.name}</Link>)
                    }
                </th>
                <td className="px-6 py-4 min-w-[100px]">
                    <img src={props.imgSrc} alt={props.description}/>
                </td>
                <td className="px-6 py-4 min-w-[100px]">${props.minPrice}-${props.maxPrice}</td>
                <td className="px-6 py-4">
                    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                        {pastWidth > 0
                            ? <><div className={"bg-gray-600 float-left text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full"} style={{width: pastWidth + "%", marginLeft: shiftWidth + "%"}}>
                                &nbsp;
                            </div>
                            <div className={`${background} text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-r-full`} style={{width: remWidth + "%", marginLeft: `${pastWidth + shiftWidth}%`}}>
                                <span style={{display: "none"}}>{ageDescription(props.minAge)} to {ageDescription(props.maxAge)} old</span>
                                &nbsp;
                            </div></>
                            : <div className={`${background} text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`} style={{width: `${pastWidth+remWidth}%`, marginLeft: shiftWidth+"%"}}>
                                <span style={{display: "none"}}>{ageDescription(props.minAge)} to {ageDescription(props.maxAge)} old</span>
                                &nbsp;
                            </div>
                        }
                    </div>
                </td>
            </tr>
        </>
    )
}

async function getProductDB() {
    const csvFilePath = 'product_db.csv';

    return new Promise<ProgressProps[]>((resolve, reject) => {
        fetch(csvFilePath)
            .then(response => {
                if (!response.ok) {
                    reject('failed to fetch csv file');
                    return '';
                }

                return response.text();
            })
            .then(csvText => {
                const csvData: ProgressProps[] = [];

                let firstLine = true;

                csvText
                    .trim()
                    .split("\n")
                    .forEach(line => {
                        if (firstLine) {
                            firstLine = false;
                        } else {
                            const cols = line.split(',');

                            const [id, name, imgSrc, description] = cols.slice(0, 4);

                            const [minAge, maxAge, minPrice, maxPrice] = cols.slice(4,8).map((v) => { return parseInt(v); });

                            const have = (cols[8].trim() === 'TRUE');
                            const variant = cols[9].trim();
                            const disabled = cols[10].trim() == 'TRUE';
                            const subvariant = cols[11];

                            console.log(name);

                            csvData.push({id, name,imgSrc,description,minAge,maxAge,minPrice,maxPrice,have, variant, disabled, subvariant});
                        }
                    });

                resolve(csvData);
            }).catch(error => {
                reject(error);
            });
    });
}

export default function Test() {
    const [rows, setRows] = useState<ProgressProps[] | null>(null);
    const [age, setAge] = useState(0);
    const [dob, setDOB] = useState<Date | null>(null);

    const handleDOB = (selectedDate: Date) => {
        const today = new Date();
        const age = Math.max((today.getFullYear() - selectedDate.getFullYear()) * 12 + (today.getMonth() - selectedDate.getMonth()), 0);

        setDOB(selectedDate);
        setAge(age);

        localStorage.setItem('DOB', selectedDate.toString());
        localStorage.setItem('age', age.toString());
    };

    const currentAge = () => {
        const savedData = localStorage.getItem('age');

        if (savedData) {
            setAge(parseInt(savedData));
            return parseInt(savedData);
        } else {
            setAge(0);
            return 0;
        }
    }


    useEffect(() => {
        getProductDB()
            .then((rows: ProgressProps[]) => {
                setRows(rows); 
            }).catch((error) => {
                setRows(null);
            })

        currentAge();
    }, []);

    const [show, setShow] = useState<boolean>(false);
    const options = {};

    if (rows !== null) {
       return (
            <div className="flex justify-center">
                <table className="w-4/5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                        <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Use this checklist to keep you organized as you prepare for your little one to arrive</p>
                        <Datepicker show={show} setShow={(state) => setShow(state)} options={options}  onChange={handleDOB} />
                    </caption>
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3 min-w-[100px] w-5">Have?</th>
                        <th scope="col" className="py-3 min-w-[100px] w-5">Item</th>
                        <th className="px-6 py-3 min-w-[100px] w-5">Image</th>
                        <th scope="col" className="px-6 py-3 min-w-[100px] w-5">Price Range</th>
                        <th>Ages Product is Used</th>
                    </tr>
                    </thead>
                    <tbody>
                        {rows.map((item: ProgressProps) => (
                            <ProgressBar {...{props: item, age: age}}/>
                        ))}
                    </tbody>
                    <tfoot>
                    </tfoot>
                </table>
            </div>
        );
    } else {
        return (<></>);
    }
}
