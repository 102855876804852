export default function About() {
    return (
        <div className="flex justify-center">
            <div className="p-6 w-4/5 bg-white rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold mb-4">Founder's Letter, 2022</h1>
                <p className="text-lg mb-6">Qpid was founded on the idea that the shopping experience should be designed for...(you guessed it) shoppers!</p>
                <a href="#" className="bg-blue-500 text-white px-6 py-3 rounded-lg">Buy Now</a>
            </div>
        </div>
    );
}
