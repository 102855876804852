import React from 'react';
import './assets/App.css';
import './assets/index.css';

import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import Checklist from './components/checklist';
import ProductFinder from './components/productFinder';
import Home from './components/home';
import About from './components/about';
import { useEffect } from 'react';


function App() {
//  useEffect(() => {
//    const script = document.createElement('script');
//    script.src = 'https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js';
//    script.async = true;
//    script.setAttribute('data-name', 'BMC-Widget');
//    script.setAttribute('data-cfasync', 'false');
//    script.setAttribute('data-id', 'shopqpid');
//    script.setAttribute('data-description', 'Support me on Buy me a coffee!');
//    script.setAttribute('data-message', "Thank you for fueling us to build search that doesn't suck!");
//    script.setAttribute('data-color', '#FF813F');
//    script.setAttribute('data-position', 'Right');
//    script.setAttribute('data-x_margin', '18');
//    script.setAttribute('data-y_margin', '18');
//
//    document.body.appendChild(script);
//
//    return () => {
//      document.body.removeChild(script);
//    };
//  }, []);
  return (
    <>
        <Helmet>
            <title>Qpid - Your PersonalAI shopper</title>
            <meta
                name="description"
                content="Qpid is a free personal shopper that helps you figure out exactly what you need without any hassle"
            />
            <link rel="icon" href="/favicon.ico"/>

            <script>
                {`
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:5005907,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
            </script>
            <script>
                {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PTXRVBFH');
                `}
            </script>
        </Helmet>
        <Routes>
            <Route path="/" element={<Navbar />}>
                <Route index element={<Home/>}/>
                <Route path="/checklist" element={<Checklist/>}/>
                <Route path="/product/:variant/:subvariant" element={<ProductFinder/>}/>
                <Route path="/product/:variant" element={<ProductFinder/>}/>
                <Route path="/about" element={<About/>}/>
                {/*<Route path="*" element={<NoMatch/>}/>*/}
            </Route>
        </Routes>
    </>
  );
}

export default App;
